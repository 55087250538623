<template>
    <div>
        <b-sidebar id="addPayments" aria-labelledby="sidebar-no-header-title" no-header width="50rem" backdrop style="direction:ltr" right :title="lang.make_payment" shadow >
            <template #default="{ hide }">
            <v-form
                ref="forinv" 
                lazy-validation
            >
                <div class="m-1 text-start" style="direction:rtl">
                    <div style="width:120px;margin-right:10px;background:#000;color:#FFF;border-radius:5px" class="btn-primary btn-sm text-center">
                        <span>{{lang.make_payment}}</span>
                    </div>
                    <div id="HideMec" @click="hide" style="float:left;margin-left:5px;margin-top:-25px;cursor:pointer;width:100px;text-align:center;" class="btn-danger btn-sm">
                        <span>{{lang.close}}</span>
                    </div>
                </div>
                <v-divider></v-divider>
                <v-row style="direction:rtl;margin:2px;" class="m-2">
                    <v-col cols="12" md="4" sm="12">
                        <label>{{lang.payment_amount}}</label>
                        <b-form-input
                            class="inborder"
                            v-model="amount1"
                        />
                    </v-col>
                    <v-col cols="12" md="4" sm="12">
                        <label>{{lang.mobile}}</label>
                        <b-form-input
                            class="inborder"
                            v-model="mobile1"
                        />
                    </v-col>
                    <v-col cols="12" md="4" sm="12">
                        <label>{{lang.customer_name}}</label>
                        <b-form-input
                            class="inborder"
                            v-model="full_name1"
                            readonly
                        />
                    </v-col>
                    <v-col cols="12" md="4" sm="12">

                        <label>{{$parent.lang.payment_method}}</label>
                        <b-input-group>
                            <b-form-select
                                class="selborder"
                                v-model="spayMethod"
                                :options="payMethodss"
                            />
                            <b-input-group-append style="margin-right:-20px;margin-top:5px;">
                            <i class="fas fa-arrow-down"></i> 
                            </b-input-group-append>
                        </b-input-group>
                    </v-col>
                    <v-col cols="12" md="8" sm="12">
                        <label>{{lang.notes}}</label>
                        <b-form-textarea
                            class="inborder"
                            v-model="notes"
                        />
                    </v-col>
                </v-row>
            </v-form>
                <div class="d-flex text-light align-items-center px-3 py-2">
                    <b-button type="button" @click="addPayme();" variant="success" class="ma-2" style="width:150px;">{{lang.make_payment}}</b-button>
                </div>
            </template>

            
        </b-sidebar>
        <!-- <vue-snotify></vue-snotify> -->
    </div>
</template>
<script>
import axios from 'axios'
import {SnotifyPosition} from 'vue-snotify';
export default {
    // props: ['amount1','mobile1','full_name1'],
    data() {
        return {
            invid:0,
            fieldRuless: [
                v => !!v || this.lang.required_field
            ],
            spayMethod: 1,
            payMethodss: [
                {text: this.$parent.lang.cash_payment, value: 1},
                {text: this.$parent.lang.span_payment, value: 2},
            ],
            amount1: '',
            amount2: '',
            mobile1: '',
            notes: '',
            full_name1: ''
        }
    },
    created() {
        this.getPyamentType();
    },
    methods: {
        addPayme(){
            if(parseFloat(this.amount1) > parseFloat(this.amount2)){
                const message = 'لا يمكن تسديد دفعة أكبر من المبلغ المطلوب';
                this.$snotify.error(message, 'تنبيه', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.$parent.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                this.amount1 = this.amount2;
                return false;
            }
            const post = new FormData();
            post.append('type','addPaymentToInv');
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[invid]',this.invid);
            post.append('data[amount]',this.amount1);
            post.append('data[mobile]',this.mobile1);
            post.append('data[full_name]',this.full_name1);
            post.append('data[paytype]',this.spayMethod);
            post.append('data[notes]',this.notes);
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                const res = response.data;
                //console.log(res);
                const message = 'تمت اضافة الدفعة بنجاح';
                this.$snotify.error(message, 'تنبيه', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.$parent.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                this.$parent.getInvoice();
                // this.$parent.getInvoice();
               window.open("../api/print.php?invid="+this.invid);
                document.getElementById('HideMec').click();
            }).then(() => {
                //this.$router.push({path: '/invoicelist'})
            })
        },
        getPyamentType(){
            const post = new FormData();
            post.append('type','getPayTypes');
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[id]',"all");
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                const res = response.data;
                let t = [];
                for(let i=0;i<res.results.data.length;i++){
                    t.push({
                        text: this.$parent.lang.langname == 'ar' ? res.results.data[i].type_name : res.results.data[i].type_name_en,
                        value: res.results.data[i].id
                    })
                }
                this.payMethodss = t;
                this.spayMethod = res.results.data[0].id;
            })
        }
    },
    computed: {
        lang: function(){
            if(this.$store.state.deflang == 'en'){
            return this.$store.state.lang.en;
            }else{
            return this.$store.state.lang.ar;
            }
        },
    },
}
</script>